import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../config/typography"

const BlogIndex = ({ data, location }) => {
  const site = data.site.siteMetadata.main
  const posts = data.allMarkdownRemark.edges

  const articleTitleFont = "Open Sans"
  const articleDescriptionFont = "Open Sans"
  const articleMetaFont = "Open Sans"

  return (
    <Layout location={location} site={site}>
      <SEO title="All posts" />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}>
            <header>
              <h3
                style={{
                  fontFamily: `${articleTitleFont}`,
                  marginTop: rhythm(3 / 2),
                  marginBottom: 0,
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small
                style={{
                  fontFamily: `${articleMetaFont}`,
                  fontWeight: 600,
                }}
              >
                {node.frontmatter.date}
              </small>
            </header>
            <section>
              <p
                style={{
                  fontFamily: `${articleDescriptionFont}`,
                  marginBottom: rhythm(1 / 4),
                }}
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        main {
          title
          description
          siteUrl
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
